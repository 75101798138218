import { Duration } from "luxon"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Container } from "../ui/Container"
import { Card, CardTitle, CardBody, CardAction } from "../ui/Cards"
import { Chips, Chip } from "../ui/Chips"
import styled from "@emotion/styled"

const ContentCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 32px;
  align-items: flex-start;
  margin-bottom: 32px;
`

/**
 * Format statistic chip
 */
const FormatChip = (props) => {
  const { format, count } = props

  const label = {
    TV: "TV",
    OVA: "OVA",
    TV_SHORT: "Shorts",
    MOVIE: "Movies",
    SPECIAL: "Specials",
    ONA: "ONA",
  }[format]

  return (
    <Chip>
      {label}: {count}
    </Chip>
  )
}

/**
 * /anime/ page
 */
const Anime = () => {
  const data = useStaticQuery(graphql`
    query AnimeInfoQuery {
      aniList {
        User(name: "dantman") {
          id
          name
          profileUrl: siteUrl
          statistics {
            anime {
              count
              minutesWatched
              episodesWatched
              formats {
                format
                minutesWatched
                count
              }
              statuses {
                status
                count
                minutesWatched
              }
            }
          }
        }
      }
    }
  `)
  const { profileUrl, statistics } = data.aniList.User

  const daysWatched =
    Math.floor(
      Duration.fromObject({ minutes: statistics.anime.minutesWatched })
        .shiftTo("days")
        .get("days") / 10
    ) * 10

  return (
    <Layout title="Anime">
      <Container>
        <ContentCardContainer>
          <Card>
            <CardTitle>Anime List</CardTitle>
            <CardBody>
              <p>
                I keep my anime list roughly up to date. I've been keeping it
                since I started watching.
              </p>
            </CardBody>
            <Chips>
              {statistics.anime.formats.map((fmt) => (
                <FormatChip key={fmt.format} {...fmt} />
              ))}
              <Chip>{`Watch time: ${daysWatched}+ days`}</Chip>
            </Chips>
            <CardAction href={profileUrl}>Visit List</CardAction>
          </Card>
          <Card>
            <CardTitle>Vancouver Anime</CardTitle>
            <CardBody>
              <p>
                I run a website listing anime related groups, conventions,
                stores, and japanese events located in the Metro Vancouver area.
              </p>
            </CardBody>
            <CardAction href="https://vancouver-anime.com/">Visit</CardAction>
          </Card>
          <Card>
            <CardTitle>
              <del>My Collection</del>
            </CardTitle>
            <CardBody>
              <p>
                <del>
                  I have a large collection of DVDs (mostly anime), manga, and
                  magazines. I used to keep track of it for my family who never
                  knew what I already had when buying gifts.
                </del>
              </p>
            </CardBody>
            <CardAction to="/collection/">Collection</CardAction>
          </Card>
        </ContentCardContainer>
      </Container>
    </Layout>
  )
}

export default Anime
