import styled from "@emotion/styled"

export const Chips = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -4px;
  margin-top: 8px;
  padding: 0;
  list-style: none;
`
export const Chip = styled.li`
  display: block;
  margin: 0 4px;
  margin-bottom: 8px;
  padding: 4px 8px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  white-space: nowrap;
`
